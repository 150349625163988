.pre {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	z-index: 999;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	.loader {
		border: 16px solid #f3f3f3;
		border-radius: 50%;
		border-top: 16px solid #000000;
		width: 120px;
		height: 120px;
		animation: spin 2s linear infinite;
		@media (max-width: 767px) {
			width: 80px;
			height: 80px;
			border-width: 12px; } } }


@keyframes spin {
	0% {
		 transform: rotate(0deg); }
	100% {
		transform: rotate(360deg); } }
