.footer {
	border-top: $black 2px solid;
	// margin-top: 50px

	&-social {
		display: flex;
		align-items: center;

		li {
			position: relative;
			margin-right: 25px;

			a {
				display: flex;
				align-items: center;
				justify-content: center;
				flex: 0 0 26px;
				max-width: 26px;
				text-align: center;

				img {
					width: 100%;
					opacity: 1;
					transition: 0s; }
				@media(hover: hover) {
					&:hover {

						img {
							opacity: 0;
							transition: all ease .3s; }

						&.ios {
							background: url('../images/svg/ios_hov.svg') no-repeat center;
							background-size: cover; }

						&.and {
							background: url('../images/svg/and_hov.svg') no-repeat center;
							background-size: cover; }

						&.fb {
							background: url('../images/svg/fb_hov.svg') no-repeat center;
							background-size: cover; }

						&.vk {
							background: url('../images/svg/vk_hov.svg') no-repeat center;
							background-size: cover; }

						&.tw {
							background: url('../images/svg/tw_hov.svg') no-repeat center;
							background-size: cover; }

						&.in {
							background: url('../images/svg/in_hov.svg') no-repeat center;
							background-size: cover; }

						&.ok {
							background: url('../images/svg/ok_hov.svg') no-repeat center;
							background-size: cover; }

						&.tel {
							background: url('../images/svg/tel_hov.svg') no-repeat center;
							background-size: cover; }

						&.yt {
							background: url('../images/svg/yt_hov.svg') no-repeat center;
							background-size: cover; } } }
				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					&:hover {

						img {
							opacity: 0;
							transition: all ease .3s; }

						&.ios {
							background: url('../images/svg/ios_hov.svg') no-repeat center;
							background-size: cover; }

						&.and {
							background: url('../images/svg/and_hov.svg') no-repeat center;
							background-size: cover; }

						&.fb {
							background: url('../images/svg/fb_hov.svg') no-repeat center;
							background-size: cover; }

						&.vk {
							background: url('../images/svg/vk_hov.svg') no-repeat center;
							background-size: cover; }

						&.tw {
							background: url('../images/svg/tw_hov.svg') no-repeat center;
							background-size: cover; }

						&.in {
							background: url('../images/svg/in_hov.svg') no-repeat center;
							background-size: cover; }

						&.ok {
							background: url('../images/svg/ok_hov.svg') no-repeat center;
							background-size: cover; }

						&.tel {
							background: url('../images/svg/tel_hov.svg') no-repeat center;
							background-size: cover; }

						&.yt {
							background: url('../images/svg/yt_hov.svg') no-repeat center;
							background-size: cover; } } }

				.icon {
					transition: all ease .3s;
					fill: #000; } } } }

	&__copy {
		margin-top: 10px;
		font-size: 13px;
		line-height: 1.5;
		font-family: 'Montserrat';

		a {

			&:hover {
				color: $green; } } }

	&-nav {
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		max-width: 450px;
		font-size: 16px;
		font-weight: bold;
		text-transform: uppercase;
		font-family: 'Montserrat';

		li {
			margin: 0 15px;

			a {
				font-size: 16px;

				&:hover {
					color: $green; } } } }

	&-col:last-child {
		text-align: right; }

	&__nota {
		display: block;

		.icon {
			transition: all ease .3s; }

		&:hover {
			.icon {
				fill: $green; } } }

	&__created {
		margin-top: 10px;
		font-size: 13px;
		line-height: 1.5;
		font-family: 'Montserrat';

		a:hover {
			color: $green; } } }

.mos-footer {
	font-size: 14px;
	line-height: 1;
	font-weight: 400;
	margin-top: 30px;
	font-family: 'Montserrat';

	a {
		color: $green; } }

.mos-footer-popup {
	font-size: 14px;
	line-height: 1;
	font-weight: 400;
	margin-top: 30px;
	font-family: 'Montserrat';

	a {
		color: #fff;
		text-decoration: underline;

		&:hover {
			text-decoration: none; } } }

@media #{$extra-large} {

	.footer {

		&-social {

			.icon {
				width: 24px;
				height: 24px; } }

		&__copy {
			font-size: 16px; }

		&-nav {
			max-width: 600px;

			li a {
				font-size: 20px; } }


		&__nota {

			.icon {
				width: 240px;
				height: 20px; } }

		&__created {
			font-size: 16px; } } }

@media #{$tablet-above} {
	.footer {
		padding: 50px 0;

		&-duo {
			display: none; } } }

@media #{$middle} {

	.footer {

		&-nav {
			li a {
				font-size: 14px; } } } }


@media #{$tablet} {

	.footer {
		padding-bottom: 40px;

		&-duo {
			border-bottom: $black 2px solid;
			margin-bottom: 40px;

			&-row {
				display: flex;
				flex-wrap: nowrap; }

			&__link {
				flex: 0 0 50%;
				width: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 70px;

				&:first-child {
					border-right: $black 2px solid; }

				a {
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 14px;
					color: $black;
					display: block;
					font-family: 'Montserrat';
					text-transform: uppercase;
					font-weight: bold; } } }

		&-nav {
 }			// display: none

		&-social {

			li {
				margin-right: 30px; }

			.icon {
				width: 22px;
				height: 22px; } }

		&__nota {
			margin-top: 25px; } } }

@media #{$mobile} {

	.footer {
		padding-bottom: 17px;

		&-duo {

			&__link {
				text-align: center;
				padding: 0 30px; } }

		&__copy {

			p {
				padding-top: 15px; } }

		&__nota {
			text-align: left;

			.icon {
				width: 100px; } }

		&__created {
			margin: 5px 0 0 0;
			text-align: left; }

		&-social {

			li {
				margin-right: 24px; } } } }
