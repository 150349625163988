.header {
    background: #f3f3f3;
    padding: 10px 0 25px;
    border-bottom: $black 2px solid;
    position: relative;

    &-row {
        justify-content: space-between; }

    .header-col__logo {
        padding-left: 0; }

    &__logo {

        a {
            display: block;
            width: 100%;
            max-width: 400px;
            height: 225px;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url('../images/svg/logo.svg');
            outline: none;

            @media #{$ie11} {
                background-image: url('../images/logo.png'); } } }

    &-info {
        display: flex;
        justify-content: flex-end;
        padding-right: 15px; }


    &-nav {
        font-family: 'Oswald';
        width: 100%;
        max-width: 450px;

        &-list, &-menu {

            li {
                margin-bottom: 10px;
                font-size: 17px;
                line-height: 1;
                color: $black;
                text-transform: uppercase;

                a {

                    &:hover {
                        color: $green; } }

                &.active {

                    a {
                        color: $green; } } } } }

    &-auth {
        // margin-top: -13px
        &__link {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            // margin-top: -13px
            font-size: 17px;
            line-height: 1;
            color: $black;
            text-transform: uppercase;
            font-family: 'Oswald';
            a {
                transition: all ease .3s;
                &:hover {
                    color: $green; }
                &:last-child {
                    padding-left: 10px;
                    margin-left: 10px;
                    border-left: 2px solid #000; } } }


        &__photo {
            display: block;
            position: relative;
            width: 100%;
            width: 42px;
            height: 42px;
            border-radius: 50%;
            overflow: hidden;
            background-size: contain;
            background-repeat: no-repeat;
            cursor: pointer;
            transition: 0.3s; }

        &__user {
            display: flex;
            align-items: center; }
        &__name {
            max-width: 133px;
            padding-left: 7px;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: -0.01em;
            color: black;
            text-transform: uppercase; } }

    &-search {
        margin: 0 100px 0 0;

        &_mob {
            display: none; }

        &-back {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            visibility: hidden;
            opacity: 0;

            &.active {
                visibility: visible;
                opacity: 1; } }

        &__button {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            cursor: pointer;
            margin-bottom: 10px;
            font-size: 17px;
            line-height: 1;
            color: $black;
            text-transform: uppercase;
            transition: all ease .3s;
            font-family: 'Oswald';

            &:hover {
              color: $green;

              .icon {
                fill: $green; } }

            .icon {
                margin-right: 5px;
                transition: all ease .3s; } }

        &-widget {
            position: absolute;
            top: 20px;
            right: 15px;
            width: 710px;
            max-width: 60vw;
            z-index: 3;
            transition: transform ease .3s;
            transform: translateY(-300%);

            &.active {
                transform: translateY(0); } }

        &-wrap {
            position: relative; }

        &__submit {
          position: absolute;
          top: 5px;
          right: 0;
          z-index: 1;
          cursor: pointer;
          background: transparent;

          &:hover {

            .icon {
              fill: $green; } }

          .icon {
              width: 30px;
              height: 30px;
              transition: all ease .3s; } } }

    input {
        font-size: 17px;
        text-transform: uppercase;
        padding: 5px;
        width: 100%;
        height: 50px;
        border: none;
        border-bottom: $black 2px solid;
        background: #f3f3f3;
        font-family: 'Oswald';
        @include placeholder(#929292, 17px); } }

.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: $black;
    transition: all ease .3s;
    transform: translateY(-200%);
    z-index: 9;
    opacity: 0;

    &.show {
        transform: translateY(0);
        opacity: 1; }

    &-row {
        display: flex;
        align-items: center;
        height: 90px; }

    &__logo {
        width: 50px;
        height: 50px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat; }

    &__title {
        display: none;
        font-size: 64px;
        line-height: 1;
        margin-bottom: -20px;
        color: #fff;
        text-transform: uppercase;
        margin-left: 10px;
        transition: 0s;
        font-family: 'Extenda Trial 30 Deca'; }

    &-nav {
        display: flex;
        flex-wrap: nowrap;
        margin-left: 30px;

        li {
            font-size: 17px;
            line-height: 1;
            color: #fff;
            margin-right: 20px;
            text-transform: uppercase;

            a {
                opacity: .7;

                &:hover {
                    color: $green;
                    opacity: 1; } }

            &.active {
                color: #fff;
                pointer-events: none;

                a {
                    opacity: 1; } } } }

    &-auth {
        position: relative;
        cursor: pointer; }

    &__photo {
        display: block;
        position: relative;
        width: 100%;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        overflow: hidden;
        background-size: contain;
        background-repeat: no-repeat;
        margin-left: 10px; }

    .header-auth__link {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 17px;
        line-height: 1;
        color: #fff;
        text-transform: uppercase;
        font-family: 'Oswald';
        a {
            transition: all ease .3s;
            &:hover {
                color: $green; }
            &:last-child {
                padding-left: 10px;
                margin-left: 10px;
                border-left: 2px solid #fff; } } }

    .header-dropdown {
        top: 66px;
        right: -10px; }

    &-search {
        position: relative;
        margin: 0 40px 0 auto;

        &__form {
            width: 100%; }

        &-back {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            visibility: hidden;
            opacity: 0;

            &.active {
                visibility: visible;
                opacity: 1; } }

        &__button {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            cursor: pointer;
            font-size: 17px;
            line-height: 1;
            color: #fff;
            text-transform: uppercase;

            .icon {
                margin-right: 5px;
                fill: #fff;
                width: 30px;
                height: 28px; }

            &:hover {

                .icon {
                    fill: $green; } } }

        &-widget {
            position: absolute;
            top: -10px;
            right: 45px;
            width: 710px;
            max-width: 50vw;
            z-index: 3;
            transition: transform ease .3s;
            transform: translateY(-300%);

            &.active {
                transform: translateY(0); } }

        &-wrap {
            position: relative;
            display: flex;
            align-items: flex-end; }

        &__close {
            margin-right: 15px;
            cursor: pointer; }

        .icon {
            width: 30px;
            height: 30px;
            transition: all ease .3s; }

        input {
            font-size: 17px;
            text-transform: uppercase;
            padding: 5px;
            width: 100%;
            height: 50px;
            border-bottom: #fff 2px solid;
            color: #fff;
            background: $black;
            font-family: 'Oswald';
            @include placeholder(#929292, 17px); } } }

.fixed-header {
    .header-auth__name {
        display: none; } }


@media #{$extra-large} {
    .header {

        &-nav {
            max-width: 600px;

            &-list {

                li {
                    font-size: 27px;
                    white-space: nowrap; } } }

        &-auth {

            &__link {
                min-width: 200px;
                font-size: 27px; }

            &__photo {
                display: block;
                position: relative;
                width: 100%;
                width: 62px;
                height: 62px;
                border-radius: 50%;
                overflow: hidden;
                background-size: contain;
                background-repeat: no-repeat;
                margin-left: 10px; } }

        &-search {

            &__button {
                font-size: 27px;

                .icon {
                    width: 30px;
                    height: 30px;
                    margin-right: 15px; } }

            &-widget {
                top: 13px;
                width: 1000px; }

            &__submit {
              top: 15px;

              .icon {
                  width: 30px;
                  height: 30px; } }

            input {
                font-size: 27px;
                height: 64px;
                @include placeholder(#929292, 27px); } } }

    .fixed-header {

      &-row {
        height: 120px; }

      &__logo {
        width: 70px;
        height: 80px;

        svg, img {
            width: 100%; } }

      &__title {
        font-size: 90px;
        margin-bottom: -25px; }

      &-nav {

        li {
            font-size: 27px;
            margin-right: 40px;
            text-transform: uppercase; } }

      .header-auth {

        &__link {
            min-width: 200px;
            font-size: 27px; }

        &__photo {
            width: 62px;
            height: 62px; } }

      &-search {
        position: relative;
        margin: 0 40px 0 auto;

        &__button {
            font-size: 27px;

            .icon {
                margin-right: 5px;
                fill: #fff;
                width: 45px;
                height: 35px; } }

        &-widget {
            top: -15px;
            right: 55px;
            width: 1000px; }

        &__close {

            .icon {
                width: 30px;
                height: 30px;
                transition: all ease .3s; } }

        input {
            font-size: 27px;
            height: 50px;
            border-bottom: #fff 2px solid;
            background: $black;
            @include placeholder(#929292, 27px); } } } }

@media #{$large} {
    .header {

        &-search {

            &-widget {
                width: 800px; } } } }

@media #{$middle} {
    .header {

        &__logo a {
            height: 180px; }

        &-search {
            margin: 0 50px; } }

    .fixed-header {

      &-nav {

        li {
            font-size: 13px;
            margin-right: 15px; } }

      .header-auth {

        &__link {
            min-width: 100px;
            font-size: 13px; }

        &__photo {
            width: 50px;
            height: 50px; } }

      &-search {
        position: relative;
        margin: 0 20px 0 auto;

        &__button {
            font-size: 13px;

            .icon {
                margin-right: 5px;
                fill: #fff; }

            &:hover {

                .icon {
                    fill: $green; } } }

        input {
            font-size: 13px;
            @include placeholder(#929292, 13px); } } } }

@media #{$tablet-above} {

    .header {

        &-info {
            padding-top: 34px;
            padding-bottom: 34px;
            padding-right: 15px; }

        &-nav {

            &-list {
                display: flex;
                flex-wrap: wrap;

                li {
                    flex: 0 0 50%;
                    width: 50%; } }

            &-menu {
                column-count: 2;

                li {
                    padding-right: 40px; } } }


        &-auth.mobile {
            display: none; }

        .header-mobile-button {
            display: none; } } }



@media not all and (min-resolution:.001dpcm) and (min-width: 1024px) {
    @supports (-webkit-appearance:none) {

        .header-nav-menu {
            margin-top: -13px;

            li {
                padding: 17px 40px 0px 0;
                margin: 0;

                &:first-child {
                    margin-top: -3px; } } } } }


@media #{$tablet} {

    .header {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 4;
        padding: 0;

        &-row {
            height: 90px;
            align-items: center; }

        &__logo a {
            width: 215px;
            height: 50px;
            background-image: url('../images/svg/logo-mini.svg')!important; }

        &-nav {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: fixed;
            top: 92px;
            left: 0;
            bottom: -100px;
            padding-bottom: 100px;
            max-width: none;
            width: 100%;
            background: #f3f3f3;
            transform: translateX(-100%);
            transition: transform .4s ease-in;
            will-change: auto;
            overflow: auto;
            z-index: 3;

            // &:after
            //     content: ""
            //     display: block
            //     width: 100%
            //     height: 100px
            //     background: #f3f3f3
            //     position: absolute
            //     bottom: -100px
            //     left: 0
            //
            &-list, &-menu {
                position: relative;
                padding: 50px 80px;
                border-bottom: 2px solid #000;
                height: calc(100vh - 90px - 100px);
                overflow: auto;
                z-index: 1;
                overflow-y: auto;
                li {
                    font-size: 40px;
                    line-height: 1;
                    margin-bottom: 30px; } }

            .header-auth {
                display: flex;
                align-items: center;
                border-top: $black 2px solid;
                height: 100px;
                padding: 0 80px;

                &__link {
                    justify-content: flex-start; }
                //   margin-top: 0
                //   flex-direction: row-reverse

                &__photo {
                    margin: 0 10px 0 0; } } }

        .header-auth__link {
            font-size: 16px; }

        &-mobile-button {
            display: flex;
            align-items: center;
            width: 140px;
            height: 26px;
            padding: 8px 10px 10px;
            box-sizing: border-box;
            transition: all ease .3s;
            cursor: pointer;
            outline: none;

            .lines {
                position: relative;
                display: block;
                width: 100%;
                max-width: 20px;
                height: 2px;
                -webkit-transition: all 0.3s;
                transition: all 0.3s;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
                border-radius: 3px;
                background-color: $black;

                &:after, &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: -6px;
                    background-color: $black;
                    -webkit-transition: all 0.3s;
                    transition: all 0.3s;
                    -webkit-backface-visibility: hidden;
                    backface-visibility: hidden;
                    border-radius: 3px; }

                &:after {
                    top: 6px;
                    width: 100%;
                    height: 2px; }

                &:before {
                    width: 100%;
                    height: 2px; } } }

        .header-col {
            flex: 0 0 20%;
            max-width: 20%; }

        &-search {
            display: none; }

        &-search__close {
            display: none; }

        &-search_mob {
            position: relative;
            padding: 25px 80px;
            display: flex;
            align-items: center;
            background: #f3f3f3;
            z-index: 2;
            .header-search-widget {
                position: static;
                transform: none; }
            input {
                border-bottom: none;
                padding: 5px 5px 5px 25px;
                &::placeholder {
                    color: #000; } }
            .header-search__submit {
                left: 0;
                right: auto;
                top: 17px;
                background: transparent;
                .icon {
                    width: 17px;
                    height: 17px; } } } }


    .fixed-header {
        display: none; }


    .is-menu-open {
        .header {

            &-search {
                visibility: visible;
                opacity: 1; }

            &-nav {
                transform: translateX(0); }

            &-mobile-button .lines {
                background-color: transparent;

                &:before {
                    -webkit-transform: rotate(45deg) translate(6px, 4px);
                    transform: rotate(45deg) translate(6px, 4px); }

                &:after {
                    -webkit-transform: rotate(-45deg) translate(4.5px, -2.5px);
                    transform: rotate(-45deg) translate(4.5px, -2.5px); } } } }

    .is-dropdown-open {
        .header-auth {
            background-image: url('../images/svg/close.svg');
            background-repeat: no-repeat;
            background-size: 15px 15px;
            background-position: center;
 }            // transition: 0.3s
        .header-auth__photo {
            opacity: 0; } } }


@media #{$mobile-above} {

    .header-auth_mob {

        .header-auth__link {
            display: none; } } }

@media #{$mobile} {

    .header {

        .header-col {
            &__logo {
                flex: 0;
                max-width: none;
                padding: 0; } }

        &-row {
            height: 70px;
            align-items: center; }

        &__logo {

            a {
                width: 97px;
                height: 47px; } }

        &-nav {
            top: 72px;
            bottom: -75px;
            padding-bottom: 75px;
            height: inherit;

            &-list, &-menu {
                padding: 20px 50px;
                height: calc(100vh - 70px - 68px);

                li {
                    font-size: 22px;
                    line-height: 1;
                    margin-bottom: 23px; } } }

        &-mobile-button {
            width: 62px; }

        .header-auth {
            &_top {
                [data-mfp-src] {
                    display: none; } }
            &_mob {
                background: #f3f3f3;
                padding: 30px 50px 15px;
                z-index: 2; } }

        &-search {
            margin-right: 40px;

            &__button {
                font-size: 0; }

            .icon-search {
                width: 18px;
                height: 18px; }

            &-widget {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                max-width: none;
                background: #f3f3f3; }

            &-wrap {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                height: 68px; }

            &_mob {
                padding: 0 25px;
                .header-search__submit {
                    position: relative;
                    top: 0;
                    right: 0;
                    left: 0;
                    background: transparent; } }

            input {
                border: none;
                height: 20px;
                font-size: 16px;
                width: 100%;
                padding: 0 30px;
                text-transform: none;
                font-family: 'Montserrat'!important; }


            &__close {
                background: transparent;

                .icon-close {
                    width: 14px;
                    height: 14px;
                    stroke: $black;
                    margin-right: 15px; } } } }

    .is-menu-open {
        .header-auth_top {
            .header-auth__link {
                visibility: hidden; } } } }
