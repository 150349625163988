$extra-large: 'screen and(min-width: 1921px)';
$large: 'screen and(min-width: 1600px)';
$laptop: 'screen and(max-width: 1440px)';
$tablet: 'screen and(max-width: 1024px)';
$middle-only: 'screen and(min-width: 1025px)and(max-width: 1200px)';
$middle: 'screen and(min-width: 1025px)and(max-width: 1599px)';
$tablet-only: 'screen and(min-width: 768px)and(max-width: 1024px)';
$tablet-above: 'screen and(min-width: 1025px)';
$tablet-portret-only: 'screen and(min-width: 768px)and(max-width: 1023px)';
$mobile: 'screen and(max-width: 767px)';
$mobile-above: 'screen and(min-width: 768px)';
$small: 'screen and(max-width: 320px)';
$retina: "(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx)";
$ie11: "all and (-ms-high-contrast:none)";


$green: #3AB083;
$green_hover: #2CA778;
$green_focus: #2E9A71;


$black: #000000;
$yellow: #FFD137;
$red: #EB5F62;
$bg: #F3F3F3;
$blue: #4D78CB;
