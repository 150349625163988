html,
body {
    height: 100%;
    overflow-x: hidden; }

body {
    position: relative;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    font-family: 'JetBrains Mono';
    background-color: #F3F3F3;

    @media #{$tablet-only} {
        padding-top: 90px; }

    @media #{$mobile} {
        padding-top: 70px; } }

*, :before, :after {
    margin: 0;
    padding: 0;
    box-sizing: border-box; }

svg {
    pointer-events: none; }

input,
textarea,
button {
    border: none;
    outline: none;
    border-radius: 0;

    input::-ms-reveal,
    input::-ms-clear {
        display: none; } }

input[type="text"],
input[type="password"],
input[type="tel"],
input[type="email"],
input[type="number"],
textarea,
button {
    -webkit-appearance: none; }

select {
    &:focus {
        outline: 2px solid #000; } }

input[type="text"],
input[type="password"],
input[type="tel"],
input[type="email"],
input[type="number"], {
    height: 72px;
    border: $black 2px solid;
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
    color: $black;
    font-size: 20px;
    font-family: 'Montserrat';
    background: #f3f3f3;
    overflow: hidden;
    text-overflow: ellipsis;
    @include placeholder(#838383, 20px);


    @media #{$tablet-only} {
        height: 52px;
        font-size: 16px;
        padding: 15px 20px;
        @include placeholder(#838383, 16px); }

    @media #{$mobile} {
        height: 43px;
        font-size: 14px;
        padding: 10px 16px;
        @include placeholder(#838383, 14px); } }

textarea {
    min-height: 72px;
    border: $black 2px solid;
    width: 100%;
    padding: 16px 20px 15px 20px;
    box-sizing: border-box;
    color: $black;
    font-size: 20px;
    line-height: 26px;
    font-family: 'Montserrat';
    background: #f3f3f3;
    overflow: hidden;
    resize: none;
    @include placeholder(#838383, 20px);

    @media #{$tablet-only} {
        font-size: 16px;
        line-height: 22px;
        @include placeholder(#838383, 16px); }

    @media #{$mobile} {
        min-height: 43px;
        font-size: 14px;
        line-height: 20px;
        @include placeholder(#838383, 14px); }

    &.input {
        min-height: 72px;
        height: 72px;
        padding: 20px;

        @media #{$tablet-only} {
            min-height: 52px;
            height: 52px;
            font-size: 16px;
            padding: 14px 20px;
            @include placeholder(#838383, 16px); }

        @media #{$mobile} {
            min-height: 43px;
            height: 43px;
            font-size: 14px;
            padding: 10px 16px;
            @include placeholder(#838383, 14px); } } }



fieldset {
    border: none; }

h1, h2, h3, h4, h5, h6 {
    font-family: inherit;
    font-weight: normal;
    color: inherit; }

a {
    color: inherit;
    text-decoration: none;
    transition: all ease .3s;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none; } }

li {
    list-style: none; }

ol {
    counter-reset: count;
    list-style-position: outside;
    li {
        counter-increment: count;
        position: relative;
        &::before {
            content: counters(count, '.');
            position: absolute;
            left: -60px;
            display: block;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #000;
            color: #fff;
            font-weight: 600;
            font-size: 17px;
            line-height: 30px;
            text-align: center; } } }

th {
    font-weight: 800;
    text-transform: uppercase; }

img {
    max-width: 100%;
    height: auto; }

.visually-hidden {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    width: 1px !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important; }

.hidden-xs {
    @media #{$mobile} {
        display: none !important; } }

.visible-xs {
    display: none !important;

    @media #{$mobile} {
        display: block !important; } }

.page-wrap {
    font-family: 'Montserrat';
    font-size: 20px;
    line-height: 32px;
    font-weight: 500;
    overflow: hidden;
    &.gallery-wrap {
        overflow: initial;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            overflow: visible; } }

    @media #{$tablet} {
        font-size: 14px;
        line-height: 20px; } }

.container {
    .no-data {
        padding: 80px 0;
        @media #{$tablet} {
            padding: 50px 0; }
        @media #{$mobile} {
            padding: 35px 0; } }

    .notetext {
        color: #000; } }

.container_flex {
    display: flex;
    align-items: center;
    justify-content: space-between; }

.title {
    font-size: 138px;
    text-transform: uppercase;
    margin: 0;
    font-family: 'Extenda Trial 30 Deca';

    @media #{$extra-large} {
        font-size: 158px; }

    @media #{$tablet-only} {
        font-size: 70px; }

    @media #{$mobile} {
        font-size: 50px;
        line-height: .9; } }


.link_green {
    color: $green;
    font-family: 'JetBrains Mono'; }


.but {
    background: none;
    border: none;

    @media #{$mobile} {
        width: 100%; }

    &[disabled] {
        pointer-events: none; }

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        line-height: 1;
        height: 72px;
        padding: 0 50px;
        text-transform: uppercase;
        font-family: 'JetBrains Mono';
        transition: all ease .3s;
        cursor: pointer;

        @media #{$tablet-only} {
            height: 60px;
            font-size: 14px;
            padding: 0 40px; }

        @media #{$mobile} {
            height: 43px;
            font-size: 14px;
            padding: 0 30px;
            width: 100%;
            max-width: 400px; } }

    &:hover {

        .arrow {
            width: 40px;
            margin-right: -10px; } }

    i {
        font-style: normal;
        margin-left: 8px; }

    .icon {
        margin-left: 10px; }

    .arrow {
        position: relative;
        width: 30px;
        height: 11px;
        overflow: hidden;
        margin-left: 10px;
        transition: all ease .3s;

        .icon {
            position: absolute;
            right: 0;
            margin: 0;
            fill: $black; }

        @media #{$tablet} {
            display: none; } }

    &_green {

        &[disabled] {

            span {
                background: #838383;
                pointer-events: none; } }

        span {
            background: $green;
            color: #fff; }

        .arrow {

            .icon {
              fill: #fff; } }

        &:hover {

            span {
                background: $green_hover; } }

        &:focus {

            span {
                background: $green_focus; } } }

    &_black {

        &[disabled] {

            span {
                background: #ccc;
                pointer-events: none; } }

        span {
            background: #000;
            color: #fff; }

        .arrow {

            .icon {
              fill: #fff; } }

        &:hover {

            span {
                background: #2e2e2e; } } }


    &_white {

        &[disabled] {

            span {
                border-color: #838383;
                color: #838383;
                background: #fff; } }

        span {
            color: $black;
            border: $black 2px solid; }

        &:hover {

            span {
                background: $green_hover;
                color: #fff;
                border: $green_hover 2px solid; } }

        &:focus {

            span {
                background: $green_focus;
                border-color: $green_focus;
                color: #fff; } } } }


.link_green {
    &:hover {
        .arrow {
            width: 40px;
            margin-right: -10px; } }
    .arrow {
        display: inline-block;
        position: relative;
        width: 30px;
        height: 11px;
        overflow: hidden;
        margin-left: 5px;
        transition: all ease .3s;

        .icon-arr-green {
            position: absolute;
            right: 0;
            margin: 0; } } }

table {
    width: 100%;
    border-collapse: collapse;
    th,td {
        position: relative;
        text-align: left; }
    th {
        border-bottom: 2px solid #000000;
        border-top: 2px solid #000000; } }

.dv-message {
	padding: 31px 29px;
	background-color: #3AB083;
	position: fixed;
	left: 26px;
	bottom: 26px;
	min-width: 434px;
	opacity: 1;
	z-index: 999999;
	&__icon {
		position: absolute;
		top: 29px;
		right: 29px;
		width: 27px;
		height: 27px;
		display: flex;
		transform: rotate(45deg);
		background-color: transparent;
		svg {
			width: 100%;
			height: 100%; } }
	&__title {
		font-family: Montserrat;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 31px;
		text-transform: uppercase;
		color: #FFFFFF; }
	&__text {
		font-family: Montserrat;
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 30px;
		max-width: 266px;
		color: #FFFFFF;
		margin-top: 6px; }
	&__visibility {
		display: none; }
	@media #{$mobile} {
		left: 12px;
		bottom: 15px;
		padding: 15px;
		min-width: 296px;
		&__icon {
			top: 15px;
			right: 15px;
			width: 22px;
			height: 22px; }
		&__title {
			display: none; }
		&__text {
			font-size: 12px;
			line-height: 18px;
			letter-spacing: -0.01em;
			max-width: 181px;
			margin-top: 0px; } } }

.page_auth {
    padding: 15vh 0;
    .title {
        line-height: .9; }

    @media #{$mobile} {
        padding: 50px 0; } }
.empty-search-message {
	padding-bottom: 50px;
	@media #{$mobile} {
		padding-bottom: 31px; } }

a {
	cursor: pointer; }
